import React, { useState, useRef, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import ByFlipsDesign from '../../theme/ByFlipsDesign.png';
import HeaderStyles from './Header.style';
import { useDeviceInfo } from '../../hooks/useDeviceInfo';
import { FaHome, FaPhoneAlt, FaTools, FaShoppingCart, FaStore } from 'react-icons/fa';
import SubHeader from './SubHeader/SubHeader'; // Import the SubHeader component
import HomePageSubHeader from './SubHeader/HomePageSubHeader/HomePageSubHeader';

interface NavigationLink {
    key: string;
    to: string;
    icon: JSX.Element;
    subHeader?: JSX.Element; // Optional property
}

// Define the NavigationLink enum
export enum NavigationLinkKey {
    Home = 'Home',
    Products = 'Products',
    Contact = 'Contact',
    Events = 'Events',
    Investments = 'Investments', // Commented but included in the enum
    News = 'News', // Commented but included in the enum
    Endeavors = 'Endeavors', // Commented but included in the enum
    References = 'References', // Commented but included in the enum
    Projects = 'Projects',
    Cart = 'Cart',
}

export const navigationLinks: NavigationLink[] = [
    { key: NavigationLinkKey.Home, to: '/home', icon: <FaHome />, subHeader: <HomePageSubHeader /> },
    { key: NavigationLinkKey.Projects, to: '/projects', icon: <FaTools /> },
    { key: NavigationLinkKey.Products, to: '/products', icon: <FaStore /> },
    { key: NavigationLinkKey.Contact, to: '/contact', icon: <FaPhoneAlt /> },
    // { key: NavigationLinkKey.Events, to: '/events', icon: <FaCalendarAlt /> },
    // { key: NavigationLinkKey.Investments, to: '/investments', icon: <FaDollarSign /> },
    // { key: NavigationLinkKey.News, to: '/newsFeed', icon: <FaNewspaper /> },
    // { key: NavigationLinkKey.Endeavors, to: '/endeavors', icon: <FaLightbulb /> },
    // { key: NavigationLinkKey.References, to: '/references', icon: <FaBook /> },
    { key: NavigationLinkKey.Cart, to: '/cart', icon: <FaShoppingCart /> },
];
const Header: React.FC = () => {
    const location = useLocation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef<HTMLUListElement>(null);
    const { isMobile } = useDeviceInfo();
    // const [isSubHeaderVisible, setIsSubHeaderVisible] = useState(false);
    const { NavigationBar, LogoWrapper, BurgerMenu, LinkOption, NavigationLinksContainer, Icon, HeaderContainer } =
        HeaderStyles;

    // Determine if a SubHeader should be displayed based on the current location
    const currentLink = navigationLinks.find((page) => location.pathname.includes(page.to));
    const subHeaderComponent = currentLink?.subHeader;

    useEffect(() => {
        const handleOutsideInteraction = (event: MouseEvent | TouchEvent | Event) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideInteraction);
        document.addEventListener('touchstart', handleOutsideInteraction);

        return () => {
            document.removeEventListener('mousedown', handleOutsideInteraction);
            document.removeEventListener('touchstart', handleOutsideInteraction);
        };
    }, []);

    return (
        <HeaderContainer subheader={subHeaderComponent !== undefined}>
            <NavigationBar>
                <LogoWrapper>
                    <img src={ByFlipsDesign} alt="By Flips Design" />
                </LogoWrapper>
                <BurgerMenu open={isMenuOpen} onClick={() => setIsMenuOpen(!isMenuOpen)} />
                <NavigationLinksContainer ref={menuRef} open={isMenuOpen}>
                    {navigationLinks.map((page) => (
                        <LinkOption key={page.to} className={location.pathname.includes(page.to) ? 'active' : ''}>
                            <NavLink
                                to={page.to}
                                onClick={() => setIsMenuOpen(false)} // Use the new click handler
                            >
                                {isMobile && <Icon>{page.icon}</Icon>}
                                <span>{page.key}</span>
                            </NavLink>
                        </LinkOption>
                    ))}
                </NavigationLinksContainer>
            </NavigationBar>
            {subHeaderComponent && <SubHeader isVisible={true} content={subHeaderComponent} />}
        </HeaderContainer>
    );
};

export default Header;
