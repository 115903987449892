import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { HomePageSubHeaderContainer, HomeNavigationSubHeaderButton } from './HomePageSubHeader.style';

const HomePageSubHeader = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleNavigation = (section: string) => {
        navigate(`/home/${section}`);
    };

    const isActive = (section: string) => {
        return (
            location.pathname.includes(`/home/${section}`) || (location.pathname === '/home' && section === 'aboutme')
        );
    };

    return (
        <HomePageSubHeaderContainer>
            <HomeNavigationSubHeaderButton onClick={() => handleNavigation('aboutme')} selected={isActive('aboutme')}>
                About Me
            </HomeNavigationSubHeaderButton>
            <HomeNavigationSubHeaderButton
                onClick={() => handleNavigation('workexperience')}
                selected={isActive('workexperience')}
            >
                Work Experience
            </HomeNavigationSubHeaderButton>
            <HomeNavigationSubHeaderButton
                onClick={() => handleNavigation('education')}
                selected={isActive('education')}
            >
                Education
            </HomeNavigationSubHeaderButton>
        </HomePageSubHeaderContainer>
    );
};

export default HomePageSubHeader;
