import styled from 'styled-components';

// Styled Components
export const Container = styled.div`
    width: 100%;
    background-color: #191919;
    color: white;
    padding: 1.5rem; // Use rem for responsiveness
    border-radius: 0.5rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    margin: 0 auto 1.5rem; // Center container and add margin

    @media (max-width: 600px) {
        padding: 1rem;
    }
`;

export const DeleteButton = styled.button<{ disabled: boolean }>`
    background-color: #e74c3c; /* Change to a more standard red */
    color: white;
    border: none;
    padding: 0.625rem; // Use rem for consistency
    border-radius: 0.3125rem; // Use rem for consistency
    cursor: pointer;
    margin-right: 0.625rem; // Space between buttons

    &:hover {
        background-color: #c0392b; /* Darker red on hover */
    }

    @media (max-width: 600px) {
        padding: 0.5rem; // Reduce padding for mobile
    }
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;

    @media (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const Filters = styled.div`
    display: flex;
    gap: 1.5rem; // Use rem for spacing

    @media (max-width: 600px) {
        flex-direction: column;
    }
`;

export const Label = styled.label`
    font-weight: bold;
`;

export const Select = styled.select`
    margin-left: 0.5rem; // Use rem for consistency
    padding: 0.5rem;
`;

export const Content = styled.div`
    max-height: 400px; // Set a maximum height for scrolling
    overflow-y: auto; // Allow vertical scrolling

    @media (max-width: 600px) {
        max-height: 300px; // Reduce max height for mobile
    }
`;

export const NoTasksMessage = styled.p`
    text-align: center;
    color: #bdc3c7; // Light gray color for no tasks message
    font-size: 1.2rem;

    @media (max-width: 600px) {
        font-size: 1rem; // Reduce font size for mobile
    }
`;

export const Group = styled.div`
    width: 100%;
    margin-bottom: 1.5rem; // Use rem for spacing
`;

export const GroupTitle = styled.h3`
    margin: 1.5rem 0 0.5rem; // Use rem for spacing
    color: #f39c12; /* Color for group titles */
`;

export const Card = styled.div`
    background-color: #272727;
    border-radius: 0.75rem; // Use rem for consistency
    padding: 1.5rem; // Use rem for spacing
    margin: 0.75rem 0; // Use rem for spacing
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease;

    &:hover {
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.7);
        transform: translateY(-2px);
    }

    @media (max-width: 600px) {
        padding: 1rem; // Reduce padding for mobile
    }
`;

export const Item = styled.div`
    padding: 1rem; // Use rem for spacing

    @media (max-width: 600px) {
        padding: 0.5rem; // Reduce padding for mobile
    }
`;

export const Title = styled.h2`
    font-size: 1.5rem; // Use rem for consistency
    margin-bottom: 0.5rem; // Use rem for spacing
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 600px) {
        font-size: 1.2rem; // Reduce font size for mobile
    }
`;

export const Description = styled.p`
    font-size: 1rem; // Use rem for consistency
    margin-bottom: 0.5rem; // Use rem for spacing

    @media (max-width: 600px) {
        font-size: 0.9rem; // Reduce font size for mobile
    }
`;

export const DueDate = styled.p`
    font-size: 0.9rem; // Use rem for consistency
    color: #bdc3c7;
    margin: 0.5rem 0; // Use rem for spacing

    @media (max-width: 600px) {
        font-size: 0.8rem; // Reduce font size for mobile
    }
`;

export const ToggleButton = styled.button`
    background-color: #1abc9c;
    color: white;
    border: none;
    padding: 0.625rem; // Use rem for consistency
    border-radius: 0.3125rem; // Use rem for consistency
    cursor: pointer;
    margin: 0.625rem 0.625rem 0 0; // Add right margin for space between buttons

    &:hover {
        background-color: #16a085;
    }

    @media (max-width: 600px) {
        padding: 0.5rem; // Reduce padding for mobile
    }
`;

export const DetailSection = styled.div`
    margin-top: 0.625rem; // Use rem for consistency
    color: #ecf0f1;

    strong {
        display: block;
        margin-bottom: 0.3125rem; // Use rem for consistency
    }

    a {
        color: #1abc9c;
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }
`;
