import styled from 'styled-components';

export const HomePageSubHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 20px;
    background-color: transparent;

    button {
        margin: 0 5px;
    }
`;

export const HomeNavigationSubHeaderButton = styled.button<{ selected?: boolean }>`
    padding: 10px 15px;
    height: 40px;
    border: none;
    border-radius: 5px;
    background-color: ${({ selected }) => (selected ? '#0056b3' : '#007bff')}; // Active color
    color: ${({ selected }) => (selected ? 'white' : '#fff')};
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: ${({ selected }) =>
            selected ? '#004494' : '#0056b3'}; // Change hover color based on selection
    }
`;
