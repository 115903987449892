import React, { useState, useEffect } from 'react';
import { ToDoListItem } from '../../../../models/ToDoListItem';
import {
    Card,
    Container,
    Content,
    Description,
    DetailSection,
    // DueDate,
    Filters,
    GroupTitle,
    Header,
    Item,
    NoTasksMessage,
    Title,
    ToggleButton,
    DeleteButton,
    Group,
} from './ToDoList.style';
import { formatDate } from 'utils/DateUtils';
import { Badge, ExpandableContent, Status } from '../CompletedList/CompletedList.style';
import ToDoListItemForm from 'components/Forms/ToDoListItem/ToDoListItemForm';
import Modal from 'components/UI/modal/Modal';
import { useStores } from 'hooks/useStores';
import { AddToDoListItemButton } from '../ToDoPage.style';
import { useDeviceInfo } from 'hooks/useDeviceInfo';
import SubtaskModal from '../SubtaskModal/SubtaskModal'; // Import the new SubtaskModal component
import EditTaskModal from '../EditTaskModal/EditTaskModal'; // Import the new SubtaskModal component
import ReactSelectFormField from '../../../UI/FormFields/ReactSelectFormField';
import { Formik } from 'formik';

interface AddToDoItemFormProps {
    page?: string;
}

const AddToDoItemForm: React.FC<AddToDoItemFormProps> = ({ page }) => {
    const { toDoStore } = useStores();
    const { isMobile } = useDeviceInfo();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [triggerSubmit, setTriggerSubmit] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setTriggerSubmit(false);
        toDoStore.setSelectedTask(undefined);
    };

    const onCreateNewTask = async (values: ToDoListItem) => {
        try {
            await toDoStore.createTask(values);
            closeModal();
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    return (
        <>
            <AddToDoListItemButton onClick={openModal}>Add Item</AddToDoListItemButton>
            <Modal
                open={isModalOpen}
                onClose={closeModal}
                title={'Add To Do Item'}
                modalDimensions={isMobile ? { width: 900, height: 600 } : { width: 900, height: 900 }}
                onSubmit={() => setTriggerSubmit(true)}
                includeFooter={true}
            >
                <ToDoListItemForm onModalSubmitted={onCreateNewTask} submitting={triggerSubmit} page={page} />
            </Modal>
        </>
    );
};

const ToDoList: React.FC<{ pageItems?: ToDoListItem[]; page?: string }> = ({ pageItems, page }) => {
    const { toDoStore } = useStores();
    const [triggerSubmit, setTriggerSubmit] = useState(false);
    const [toDoList, setToDoList] = useState<ToDoListItem[]>([]);
    const [expandedTasks, setExpandedTasks] = useState<Record<string, boolean>>({});
    const [groupBy] = useState<'dueDate' | 'priority' | 'aspect'>('dueDate');
    const [sortBy] = useState<'title' | 'dueDate' | 'priority' | 'aspect'>('title');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSubtaskModalOpen, setIsSubtaskModalOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        const tasks = pageItems === undefined ? toDoStore.getIncompleteTasksForPage() : pageItems;
        setToDoList(tasks);
    }, [pageItems, toDoStore]);

    useEffect(() => {
        setDisabled(toDoStore.error !== null);
    }, [toDoStore.error]);

    const onUpdateTask = async (values: ToDoListItem) => {
        try {
            await toDoStore.updateTask(values);
            setIsModalOpen(false);
            setTriggerSubmit(false);
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const openModalForEdit = (task: ToDoListItem) => {
        toDoStore.setSelectedTask(task);
        setIsModalOpen(true);
    };

    const openSubtaskModal = (task: ToDoListItem) => {
        toDoStore.setSelectedTask(task);
        setIsSubtaskModalOpen(true);
    };

    const toggleExpand = (taskId: string) => {
        setExpandedTasks((prevExpanded) => ({
            ...prevExpanded,
            [taskId]: !prevExpanded[taskId],
        }));
    };

    const deleteTask = async (taskId: string) => {
        try {
            await toDoStore.deleteTask(taskId);
            setToDoList((prevToDoList) => prevToDoList.filter((item) => item.id !== taskId));
        } catch (error) {
            console.error('Error deleting task:', error);
        }
    };

    const groupAndSortTasks = () => {
        const groupedTasks =
            groupBy === 'dueDate'
                ? toDoList.reduce((acc, task) => {
                      const dueDateKey = task.dueDate ? formatDate(new Date(task.dueDate)) : 'No Due Date';
                      acc[dueDateKey] = acc[dueDateKey] || [];
                      acc[dueDateKey].push(task);
                      return acc;
                  }, {} as Record<string, ToDoListItem[]>)
                : groupBy === 'priority'
                ? toDoList.reduce((acc, task) => {
                      const priorityKey = task.priority;
                      acc[priorityKey] = acc[priorityKey] || [];
                      acc[priorityKey].push(task);
                      return acc;
                  }, {} as Record<string, ToDoListItem[]>)
                : toDoList.reduce((acc, task) => {
                      const aspectKey = task.aspect ?? 'No Aspect';
                      acc[aspectKey] = acc[aspectKey] || [];
                      acc[aspectKey].push(task);
                      return acc;
                  }, {} as Record<string, ToDoListItem[]>);

        const sortedGroupedTasks = Object.entries(groupedTasks).map(([key, tasks]) => ({
            group: key,
            tasks: tasks.sort((a, b) => {
                if (sortBy === 'title') {
                    return a.title.localeCompare(b.title);
                }
                if (sortBy === 'dueDate') {
                    const dateA = a.dueDate ? new Date(a.dueDate).getTime() : 0;
                    const dateB = b.dueDate ? new Date(b.dueDate).getTime() : 0;
                    return dateA - dateB;
                }
                if (sortBy === 'priority') {
                    return a.priority.localeCompare(b.priority);
                }
                if (sortBy === 'aspect') {
                    const aspectA = a.aspect ?? '';
                    const aspectB = b.aspect ?? '';
                    return aspectA.localeCompare(aspectB);
                }
                return 0;
            }),
        }));

        return sortedGroupedTasks;
    };

    const groupedAndSortedTasks = groupAndSortTasks();

    return (
        <Container>
            <h1>Tasks</h1>
            <Header>
                <Filters>
                    <Formik initialValues={{}} onSubmit={() => {}}>
                        <ReactSelectFormField
                            name={'Group By'}
                            options={[]}
                            value={[]}
                            optionLabel={'Group By'}
                            onChange={() => {}}
                            onBlur={() => {}}
                            label={'Group By'}
                        />
                    </Formik>

                    <Formik initialValues={{}} onSubmit={() => {}}>
                        <ReactSelectFormField
                            name={'Sort By'}
                            options={[]}
                            value={[]}
                            optionLabel={'Sort By'}
                            label={'Sort By'}
                            onChange={() => {}}
                            onBlur={() => {}}
                        />
                    </Formik>
                </Filters>
                <AddToDoItemForm page={page} />
            </Header>
            <Content>
                {groupedAndSortedTasks.length === 0 ? (
                    <NoTasksMessage>No tasks available</NoTasksMessage>
                ) : (
                    groupedAndSortedTasks.map((group, groupIndex) => (
                        <Group key={`${group.group}-${groupIndex}`}>
                            <GroupTitle>{group.group}</GroupTitle>
                            {group.tasks.map((task) => {
                                const { resourceLink, subtasks, attachments, comments } = task;

                                const hasDetails =
                                    resourceLink !== undefined ||
                                    subtasks !== undefined ||
                                    attachments !== undefined ||
                                    comments !== undefined;

                                return (
                                    <Card key={`task-${task.id}`}>
                                        <Item>
                                            <Title>
                                                {task.title}
                                                <Badge priority={task.priority}>{task.priority}</Badge>
                                            </Title>
                                            <Description>{task.description}</Description>
                                            <Status $completed={!task.completed}>
                                                {task.completed ? 'Completed' : 'Incomplete'}
                                            </Status>
                                            {/* {task.dueDate && <DueDate>Due: {formatDate(task.dueDate)}</DueDate>} */}
                                            <DeleteButton onClick={() => deleteTask(task.id)} disabled={disabled}>
                                                Delete
                                            </DeleteButton>
                                            <ToggleButton onClick={() => openModalForEdit(task)} disabled={disabled}>
                                                Edit
                                            </ToggleButton>
                                            <ToggleButton
                                                onClick={() => toDoStore.completeTask(task)}
                                                disabled={task.completed || disabled}
                                            >
                                                Complete
                                            </ToggleButton>
                                            <ToggleButton
                                                onClick={() => openSubtaskModal(task)}
                                                disabled={task.completed || disabled}
                                            >
                                                Subtasks
                                            </ToggleButton>

                                            {hasDetails && (
                                                <ToggleButton onClick={() => toggleExpand(task.id)}>
                                                    {expandedTasks[task.id] ? 'Hide Details' : 'Show Details'}
                                                </ToggleButton>
                                            )}
                                        </Item>
                                        <ExpandableContent $expanded={!!expandedTasks[task.id]}>
                                            {task.resourceLink && (
                                                <DetailSection>
                                                    <strong>Resource Link:</strong>
                                                    <a
                                                        href={task.resourceLink}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {task.resourceLink}
                                                    </a>
                                                </DetailSection>
                                            )}
                                            {task.subtasks && (
                                                <DetailSection>
                                                    <strong>Subtasks:</strong>
                                                    <ul>
                                                        {task.subtasks.map((subtask, index) => (
                                                            <li key={`subtask-${index}`}>{subtask}</li>
                                                        ))}
                                                    </ul>
                                                </DetailSection>
                                            )}
                                            {task.attachments && (
                                                <DetailSection>
                                                    <strong>Attachments:</strong>
                                                    <ul>
                                                        {task.attachments.map((attachment, index) => (
                                                            <li key={`attachment-${index}`}>{attachment}</li>
                                                        ))}
                                                    </ul>
                                                </DetailSection>
                                            )}
                                            {task.comments && (
                                                <DetailSection>
                                                    <strong>Comments:</strong>
                                                    <ul>
                                                        {task.comments.map((comment, index) => (
                                                            <li key={`comment-${index}`}>{comment}</li>
                                                        ))}
                                                    </ul>
                                                </DetailSection>
                                            )}
                                        </ExpandableContent>
                                    </Card>
                                );
                            })}
                        </Group>
                    ))
                )}
            </Content>

            <EditTaskModal
                open={isModalOpen}
                page={page}
                onClose={() => setIsModalOpen(false)}
                onSubmit={onUpdateTask}
                onUpdateTask={onUpdateTask}
                triggerSubmit={triggerSubmit}
            />

            {/* Subtask Modal here */}
            <SubtaskModal
                open={isSubtaskModalOpen}
                onClose={() => setIsSubtaskModalOpen(false)}
                onSubmit={(values) => {
                    // Handle subtask submission logic here
                    setIsSubtaskModalOpen(false);
                }}
            />
        </Container>
    );
};

export default ToDoList;
