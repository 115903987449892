import { makeAutoObservable, observable } from 'mobx';
import { ToDoListItem } from 'models/ToDoListItem';
import { toDoListApi } from 'api/flipTheCoders';
import { Subtask } from 'models/Subtask';

export class ToDoStore {
    isLoading: boolean = false;
    selectedTask: ToDoListItem | undefined;
    @observable toDoItems: ToDoListItem[] = [];
    error: string | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    getCompletedTasksForPage = (page?: string): ToDoListItem[] => {
        return this.toDoItems.filter((item) => item.completed && (page ? item.page === page : true));
    };

    getIncompleteTasksForPage = (page?: string): ToDoListItem[] => {
        return this.toDoItems.filter((item) => !item.completed && (page !== undefined ? item.page === page : true));
    };

    get completedTasks(): ToDoListItem[] {
        return this.toDoItems.filter((item) => item.completed);
    }

    get incompleteTasks(): ToDoListItem[] {
        return this.toDoItems.filter((item) => !item.completed);
    }

    getTasksForPage = (completed: boolean, page?: string): ToDoListItem[] => {
        return this.toDoItems.filter((item) => item.completed === completed && (page ? item.page === page : true));
    };

    fetchToDoList = async () => {
        this.setIsLoading(true);
        try {
            const response = (await toDoListApi.getAll()).data;
            this.setToDoItems(response);
        } catch (error) {
            this.setError('Failed to fetch to-do list');
            // console.error('Error fetching to-do list:', error);
            this.setToDoItems([
                {
                    id: '4e5a6ad4-1a19-48a6-9706-35fe078a8f31',
                    title: 'Ure React Router Dom to add to do list details page, or open the modal with the loaded details on the form ',
                    description:
                        'Come up with the best way to do this. If we only want to update the data associated to the one entity then sure leave it in modal form otherwise move it to a new page',
                    completed: true,
                    aspect: 'FRONTEND',
                    page: 'Home',
                    createdAt: '2024-10-14T19:00:33.025+00:00',
                    dueDate: '2024-10-18T00:00:00.000+00:00',
                    resourceLink: '',
                    subtasks: [],
                    attachments: [],
                    comments: [],
                    priority: 'HIGH',
                    tag: 'task',
                },
                {
                    id: '212af550-7df8-4a13-ac6c-77799c09a114',
                    title: '[FE] - Create the event recommendation form ',
                    description:
                        'Before we do that though, update this form to attach the aspect that we are working on, backend, frontend, Azure, AWS ',
                    completed: true,
                    aspect: 'BACKEND',
                    page: 'Home',
                    createdAt: '2024-10-14T19:06:01.186+00:00',
                    dueDate: '2024-10-16T00:00:00.000+00:00',
                    resourceLink: '',
                    subtasks: [],
                    attachments: [],
                    comments: [],
                    priority: 'MEDIUM',
                    tag: 'bug',
                },
                {
                    id: '429f307c-208b-4cb2-ac86-dbd7b98b98a9',
                    title: 'Start calebs website',
                    description:
                        'Take care of set up and make sure the application is hosted. After we have development set up down focus on integrating what we already have. Then focus in on images and other details.',
                    completed: true,
                    aspect: 'FRONTEND',
                    page: 'Home',
                    createdAt: '2024-10-28T23:39:44.026+00:00',
                    dueDate: '2024-11-18T00:00:00.000+00:00',
                    resourceLink: 'http://lostnfound-music.com/home',
                    subtasks: [],
                    attachments: [],
                    comments: [],
                    priority: 'LOW',
                    tag: 'task',
                },
                {
                    id: '11e73ca7-f548-457b-9f75-a7507258658b',
                    title: "Update the form that a ticket can't be complete unless it's subtasks are all complete",
                    description: 'We need to fix that i suppose',
                    completed: true,
                    aspect: 'FRONTEND',
                    page: 'Home',
                    createdAt: '2024-10-14T19:07:24.964+00:00',
                    dueDate: '2024-10-16T00:00:00.000+00:00',
                    resourceLink: 'http://flipthecoder.com/projects',
                    subtasks: [],
                    attachments: [],
                    comments: [],
                    priority: 'LOW',
                    tag: 'bug',
                },
                {
                    id: '60585af3-7cf9-49f4-8e0c-b2162a783bc9',
                    title: 'Implement Authorization for Admin Pages',
                    description: 'Add role-based access control for admin features, restricting unauthorized users.',
                    completed: false,
                    aspect: 'FRONTEND',
                    page: 'Home',
                    createdAt: '2024-10-13T16:51:07.569+00:00',
                    dueDate: '2024-10-28T00:00:00.000+00:00',
                    resourceLink: '',
                    subtasks: [],
                    attachments: [],
                    comments: [],
                    priority: 'HIGH',
                    tag: 'task',
                },
                {
                    id: '66c8b11e-823e-44c2-857b-da19b009d5b5',
                    title: 'Set Up Backend API',
                    description: 'Implement REST API for handling user requests and database interactions.',
                    completed: true,
                    aspect: 'FRONTEND',
                    page: 'Home',
                    createdAt: '2024-10-13T16:51:07.569+00:00',
                    dueDate: '2024-10-18T00:00:00.000+00:00',
                    resourceLink: 'https://backend-api-docs.com',
                    subtasks: [],
                    attachments: [],
                    comments: [],
                    priority: 'HIGH',
                    tag: 'task',
                },
                {
                    id: '8f34780b-8c36-4f54-b8dc-7838c870d2a1',
                    title: 'Azure Blob Storage Integration',
                    description: 'Set up Azure Blob Storage for file uploads and integrate it with the application.',
                    completed: false,
                    aspect: 'FRONTEND',
                    page: 'Home',
                    createdAt: '2024-10-13T16:51:07.569+00:00',
                    dueDate: '2024-10-25T00:00:00.000+00:00',
                    resourceLink: 'https://docs.microsoft.com/en-us/azure/storage/blobs/',
                    subtasks: [],
                    attachments: [],
                    comments: [],
                    priority: 'HIGH',
                    tag: 'task',
                },
                {
                    id: 'b66bb636-932a-4c75-9903-9a5055f01995',
                    title: 'Configure PostgreSQL Database',
                    description:
                        'Set up PostgreSQL database schema, tables, and establish connection with the backend.',
                    completed: true,
                    aspect: 'FRONTEND',
                    page: 'Home',
                    createdAt: '2024-10-13T16:51:07.569+00:00',
                    dueDate: '2024-10-20T00:00:00.000+00:00',
                    resourceLink: 'https://www.postgresql.org/docs/',
                    subtasks: [],
                    attachments: [],
                    comments: [],
                    priority: 'MEDIUM',
                    tag: 'task',
                },
                {
                    id: 'ed7cd5a7-30a7-40fd-a35b-587c2af04bd2',
                    title: 'AWS EC2 Instance Configuration',
                    description:
                        'Launch and configure EC2 instance to host the React application and backend services.',
                    completed: true,
                    aspect: 'FRONTEND',
                    page: 'Home',
                    createdAt: '2024-10-13T16:51:07.569+00:00',
                    dueDate: '2024-10-22T00:00:00.000+00:00',
                    resourceLink: 'https://docs.aws.amazon.com/ec2/',
                    subtasks: [],
                    attachments: [],
                    comments: [],
                    priority: 'HIGH',
                    tag: 'task',
                },
                {
                    id: 'f7590fe8-1d03-47a4-bfe7-e7dc7964bd73',
                    title: 'Finish Project',
                    description: 'Complete the final updates and refinements for the project.',
                    completed: true,
                    aspect: 'FRONTEND',
                    page: 'Home',
                    createdAt: '2024-10-13T16:51:07.569+00:00',
                    dueDate: '2025-2-15T00:00:00.000+00:00',
                    resourceLink: 'https://course-final-project.com',
                    subtasks: [],
                    attachments: [],
                    comments: [],
                    priority: 'HIGH',
                    tag: 'story',
                },
                {
                    id: 'dbdd1add-621c-40a9-9f67-cf95232e478e',
                    title: 'Update Landing Page Layout',
                    description: 'Redesign the landing page layout to improve responsiveness and user experience.',
                    completed: true,
                    aspect: 'FRONTEND',
                    page: 'Home',
                    createdAt: '2024-10-13T16:51:07.569+00:00',
                    dueDate: '2024-10-12T00:00:00.000+00:00',
                    resourceLink: 'https://developer.mozilla.org/en-US/docs/Learn/CSS/CSS_layout/Flexbox',
                    subtasks: [],
                    attachments: [],
                    comments: [],
                    priority: 'LOW',
                    tag: 'story',
                },
                {
                    id: '92de2fd8-f36d-417c-a987-d03316900242',
                    title: 'React Router Dom',
                    description:
                        '- create a subheader component \n- remove the render navigation page \n- update the url on the new subheader \n- update accordingly ',
                    completed: true,
                    aspect: null,
                    page: 'Home',
                    createdAt: '2024-10-19T23:00:59.293+00:00',
                    dueDate: '2024-10-19T00:00:00.000+00:00',
                    resourceLink: '',
                    subtasks: [],
                    attachments: [],
                    comments: [],
                    priority: 'LOW',
                    tag: 'task',
                },
                {
                    id: '09ff1b7b-ce5e-4110-9d2c-c15de42cbb70',
                    title: 'Implement Authentication and Authorization',
                    description:
                        'Add user authentication using JWT and role-based access control for specific features.',
                    completed: false,
                    aspect: 'FRONTEND',
                    page: 'Projects',
                    createdAt: '2024-10-13T16:51:07.569+00:00',
                    dueDate: '2024-10-28T00:00:00.000+00:00',
                    resourceLink: 'https://jwt.io/introduction/',
                    subtasks: [],
                    attachments: [],
                    comments: [],
                    priority: 'MEDIUM',
                    tag: 'task',
                },
                {
                    id: 'ac4d17e4-064f-43af-9f34-fa2e596e15eb',
                    title: 'Add developer mode to application',
                    description:
                        'The user can hit CTRL + ALT + D to change the layout between dev and prod mode. Dev mode will go into more detail on the concepts being used and new plans coming up',
                    completed: false,
                    aspect: 'FRONTEND',
                    page: 'Cart',
                    createdAt: '2024-10-14T18:58:54.691+00:00',
                    dueDate: '2024-11-15T00:00:00.000+00:00',
                    resourceLink: '',
                    subtasks: [],
                    attachments: [],
                    comments: [],
                    priority: 'LOW',
                    tag: 'task',
                },
            ]);
        } finally {
            this.setIsLoading(false);
        }
    };

    createTask = async (newTask: ToDoListItem) => {
        this.setIsLoading(true);
        try {
            const response = await toDoListApi.create(newTask);
            this.addToDoItem(response.data);
        } catch (error) {
            this.setError('Failed to create task');
            console.error('Error creating task:', error);
        } finally {
            this.setIsLoading(false);
        }
    };

    updateTask = async (updatedTask: ToDoListItem) => {
        this.setIsLoading(true);
        try {
            await toDoListApi.update(updatedTask.id, updatedTask);
            this.replaceToDoItem(updatedTask);
        } catch (error) {
            this.setError('Failed to update task');
            console.error('Error updating task:', error);
        } finally {
            this.setIsLoading(false);
        }
    };

    deleteTask = async (taskId: string) => {
        this.setIsLoading(true);
        try {
            await toDoListApi.delete(taskId);
            this.removeToDoItem(taskId);
        } catch (error) {
            this.setError('Failed to delete task');
            console.error('Error deleting task:', error);
        } finally {
            this.setIsLoading(false);
        }
    };

    completeTask = async (task: ToDoListItem) => {
        this.setIsLoading(true);
        try {
            await toDoListApi.complete(task.id);
            this.markTaskAsComplete(task.id);
        } catch (error) {
            this.setError('Failed to complete task');
            console.error('Error completing task:', error);
        } finally {
            this.setIsLoading(false);
        }
    };

    // Subtask Management
    createSubTask = async (taskId: string, newSubTask: Subtask) => {
        this.setIsLoading(true);
        try {
            const response = await toDoListApi.addSubTask(taskId, newSubTask);
            this.addSubTaskToTask(taskId, response.data);
        } catch (error) {
            this.setError('Failed to create subtask');
            console.error('Error creating subtask:', error);
        } finally {
            this.setIsLoading(false);
        }
    };

    updateSubTask = async (taskId: string, updatedSubTask: Subtask) => {
        this.setIsLoading(true);
        try {
            await toDoListApi.updateSubTask(taskId, updatedSubTask.id, updatedSubTask);
            this.replaceSubTaskInTask(taskId, updatedSubTask);
        } catch (error) {
            this.setError('Failed to update subtask');
            console.error('Error updating subtask:', error);
        } finally {
            this.setIsLoading(false);
        }
    };

    deleteSubTask = async (taskId: string, subTaskId: string) => {
        this.setIsLoading(true);
        try {
            await toDoListApi.deleteSubTask(taskId, subTaskId);
            this.removeSubTaskFromTask(taskId, subTaskId);
        } catch (error) {
            this.setError('Failed to delete subtask');
            console.error('Error deleting subtask:', error);
        } finally {
            this.setIsLoading(false);
        }
    };

    private addToDoItem(item: ToDoListItem) {
        this.toDoItems.push(item);
    }

    private replaceToDoItem(updatedItem: ToDoListItem) {
        this.toDoItems = this.toDoItems.map((item) => (item.id === updatedItem.id ? updatedItem : item));
    }

    private removeToDoItem(taskId: string) {
        this.toDoItems = this.toDoItems.filter((item) => item.id !== taskId);
    }

    private markTaskAsComplete(taskId: string) {
        this.toDoItems = this.toDoItems.map((item) => (item.id === taskId ? { ...item, completed: true } : item));
    }

    private addSubTaskToTask(taskId: string, subTask: Subtask) {
        // const task = this.toDoItems.find((item) => item.id === taskId);
        // if (task) {
        //     task.subtasks?.push(subTask);
        // }
    }

    private replaceSubTaskInTask(taskId: string, updatedSubTask: Subtask) {
        const task = this.toDoItems.find((item) => item.id === taskId);
        if (task) {
            task!.subtasks = task.subtasks?.map((subTask) =>
                subTask.id === updatedSubTask.id ? updatedSubTask : subTask
            );
        }
    }

    private removeSubTaskFromTask(taskId: string, subTaskId: string) {
        // const task = this.toDoItems.find((item) => item.id === taskId);
        // if (task) {
        //     task.subtasks = task.subtasks!.filter((subTask) => subTask!.id !== subTaskId);
        // }
    }

    private setToDoItems(items: ToDoListItem[]) {
        this.toDoItems = items;
    }

    private setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    private setError(message: string) {
        this.error = message;
    }

    setSelectedTask(value: ToDoListItem | undefined) {
        this.selectedTask = value;
    }
}
