export enum WorkAspect {
    FrontEnd = 'FRONTEND',
    BackEnd = 'BACKEND',
    GIT = 'GIT',
    DevOps = 'DEVOPS',
}

export enum TaskPriority {
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    HIGH = 'HIGH',
}

export enum Tag {
    STORY = 'Story',
    TASK = 'task',
    BUG = 'bug',
}

export enum Page {
    HOME = 'Home',
    PROJECTS = 'Projects',
    PRODUCTS = 'Products',
    CONTACT = 'Contact',
    EVENTS = 'Events',
    CART = 'Cart',
}

// Separate interface for subtasks
export interface Subtask {
    id: string;
    title: string;
    description: string;
    completed: boolean;
    createdAt: Date;
    priority: TaskPriority; // Priority field for subtasks
    tag: Tag; // Each subtask is tagged as "task"
}

export interface ToDoListItem {
    id: string;
    title: string;
    description: string;
    completed: boolean;
    createdAt?: Date | string;
    dueDate?: Date | string | null; // Optional due date
    priority: TaskPriority | string; // Priority field
    resourceLink?: string; // Optional resource link for helpful materials
    subtasks?: Subtask[] | string[]; // Optional subtasks
    attachments?: string[]; // Optional list of attachments
    comments?: string[]; // Optional list of comments
    tag: Tag | string;
    aspect?: WorkAspect | string | null; // Aspect of the task (Frontend, Backend, Git, DevOps)
    page: string;
}
